const EnvironmentTypes = {
  DEVELOPMENT: "development",
  TESTING: "testing",
  STAGING: "staging",
  PRODUCTION: "production",
};

exports.EnvironmentTypes = EnvironmentTypes;

exports.currentEnvironment =
  process.env.GATSBY_APP_ENVIRONMENT || EnvironmentTypes.DEVELOPMENT;
