import { graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import ImageTiles from "../widgets/ImageTiles";
import { currentEnvironment, EnvironmentTypes } from "../../environment";

export default function CaseStudiesPage({
  data: { allMarkdownRemark: { nodes: caseStudies = [] } = {} } = {},
}) {
  const isProd =
    currentEnvironment === EnvironmentTypes.PRODUCTION ||
    currentEnvironment === EnvironmentTypes.STAGING;

  const items = caseStudies?.reduce(
    (acc, { frontmatter: { title, slug, previewImage, devOnly = true } }) => {
      if (isProd && devOnly) {
        return acc;
      }

      return [
        ...acc,
        {
          label: title,
          image: previewImage,
          linkUrl: slug,
        },
      ];
    },
    []
  );

  return (
    <Layout title="Case Studies">
      {items.length ? (
        <ImageTiles sectionTitle="Case Studies" items={items} />
      ) : (
        <p>There are currently no any case studies.</p>
      )}
    </Layout>
  );
}

CaseStudiesPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query SavCaseStudiesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/case-studies/**" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          uid
          slug
          devOnly
          previewImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
